import { PastWinner } from "../../constants/dataConstant"
import ButtonElement from "../../components/ButtonElement"
import useGA from "../../hooks/useGA"
import useClevertap from "../../hooks/useClevertap"

type FandomFlexElementMobileProps = {
    concert: PastWinner
    index: number
}

const FandomFlexElementMobile = ({concert, index}: FandomFlexElementMobileProps) => {

    const { recordGAEvent } = useGA()
    const { handleEventPushClick } = useClevertap()

    const handleClick = () => {
        recordGAEvent(`ViewWinner_${index+1}_MZ`)
        handleEventPushClick(`ViewWinner_${index+1}_MZ`, {})
        window.open(concert.link , "_blank");
    }

    return (
        <div className="w-[160px] py-2 flex flex-col justify-center items-center bg-[#D9D9D9] flex-shrink-0">
            <img src={concert.image} className="w-[140px] h-[140px]" />
            <div className="font-indie-flower text-[18px] leading-[100%] text-[#000000] mt-2">
                {concert.artist}
            </div>
            <div className="font-indie-flower text-[16px] leading-[100%] text-[#000000] mt-1">
                {`${concert.date}, ${concert.city}`}
            </div>
            <div className="flex justify-center items-center mt-2">
                <ButtonElement text="View Winner" disabled={false} clickHandler={handleClick} />
            </div>
        </div>
    )
}

export default FandomFlexElementMobile
