import { useState } from "react"
import { PastWinner } from "../../constants/dataConstant"
import ButtonElement from "../../components/ButtonElement"
import useGA from "../../hooks/useGA"
import useClevertap from "../../hooks/useClevertap"

type FandomFlexElementDesktopProps = {
    concert: PastWinner
    index: number
}

const FandomFlexElementDesktop = ({concert, index}: FandomFlexElementDesktopProps) => {
    const [isHovered, setIsHovered] = useState(false)

    const { recordGAEvent } = useGA()
    const { handleEventPushClick } = useClevertap()

    const handleClick = () => {
        recordGAEvent(`ViewWinner_${index+1}_MZ`)
        handleEventPushClick(`ViewWinner_${index+1}_MZ`, {})
        window.open(concert.link , "_blank");
    }

    return (
        <div className="relative w-[262.98px] h-[305px] flex flex-col flex-shrink-0 justify-center items-center bg-[#D9D9D9]"
            onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}
        >
            <img src={concert.image} className="w-[235.87px] h-[223.67px]" />
            <div className="font-indie-flower text-[24px] leading-[80%] text-[#000000] mt-4">
                {concert.artist}
            </div>
            <div className="font-indie-flower text-[18px] leading-[100%] text-[#000000] mt-1">
                {`${concert.date}, ${concert.city}`}
            </div>

            {
                isHovered ? (
                    <div 
                        className={`absolute inset-0 bg-black flex justify-center items-center ${isHovered ? 'bg-opacity-80' : 'bg-opacity-0'}`} 
                    >
                        <ButtonElement text={"View Winner"} disabled={false} clickHandler={handleClick}/>
                    </div>
                ) : <></>
            }
        </div>
    )
}

export default FandomFlexElementDesktop
