import { FaInstagram, FaTwitter, FaTiktok, FaLinkedin } from "react-icons/fa";

import fanVideo1 from "../assets/videos/video01.webm"
import fanVideo2 from "../assets/videos/video03.webm"
import fanVideo3 from "../assets/videos/sabrina-fan.webm"

import concert_coldplay_mum from "../assets/images/concert-coldplay-mumbai-jan18.png"
import concert_coldplay_abu_2 from "../assets/images/concert-coldplay-abudhabi-jan12.png"

import past_concert_1 from "../assets/images/past-concert-adele.png"
import past_concert_2 from "../assets/images/past-concert-sabrina.png"
import past_concert_3 from "../assets/images/past-concert-imagine-dragons.png"
import past_concert_4 from "../assets/images/past-concert-sabrina-2.png"
import past_concert_5 from "../assets/images/past-concert-dua-lipa.png"
import past_concert_6 from "../assets/images/past-concert-vampire-weekend.png"
import past_concert_7 from "../assets/images/past-concert-coldplay-abu-dhabi-11-jan.png"

import Drake from "../assets/images/Drake.webp"
import JuiceWRLD from "../assets/images/JuiceWRLD.webp"
import Kanye from "../assets/images/kanye.jpg"
import KenCarson from "../assets/images/KenCarson.webp"
import KendrickLamar from "../assets/images/KendrickLamar.jpeg"
import PlayboiCarti from "../assets/images/PlayboiCarti.webp"
import TaylorSwift from "../assets/images/TaylorSwift.jpg"
import TravisScott from "../assets/images/TravisScott.webp"
import TylerTheCreater from "../assets/images/TylerTheCreator.webp"
import Yeat from "../assets/images/Yeat.jpg"
import AnuvJain1 from "../assets/images/AnuvJain1.jpg"
import AnuvJain2 from "../assets/images/AnuvJain2.jpg"
import AnuvJain3 from "../assets/images/AnuvJain3.webp"

import PaperKites1 from "../assets/images/PaperKites1.webp"
import PaperKites2 from "../assets/images/PaperKites2.webp"

import AdityaRikhari1 from "../assets/images/AdityaR1.jpg"
import AdityaRikhari2 from "../assets/images/AdityaR2.jpg"
import AdityaRikhari3 from "../assets/images/AdityaR3.webp"
import AdityaRikhari4 from "../assets/images/AdityaR4.jpg"
import AdityaRikhari5 from "../assets/images/AdityaR5.jpg"
import AdityaRikhari6 from "../assets/images/AdityaR6.jpg"
import AdityaRikhari7 from "../assets/images/AdityaR7.jpg"
import AdityaRikhari8 from "../assets/images/AdityaR8.webp"

import BharatChauhan1 from "../assets/images/BharatChauhan1.jpg"
import BharatChauhan2 from "../assets/images/BharatChauhan2.jpg"
import BharatChauhan3 from "../assets/images/BharatChauhan3.jpg"
import BharatChauhan4 from "../assets/images/BharatChauhan4.jpg"
import BharatChauhan5 from "../assets/images/BharatChauhan5.jpg"
import BharatChauhan6 from "../assets/images/BharatChauhan6.jpg"
import BharatChauhan7 from "../assets/images/BharatChauhan7.jpg"
import FrankOcean from "../assets/images/FrankOcean.webp"

export const ASSET_MONEY_TITLE = 'Asset Money';
export const CLEVERTAP_ACCOUNT_ID = 'RZK-9RW-686Z';

export const HOMEPAGE_ARTISTS = [
    {
        "id": "999644772",
        "name": "SEVENTEEN",
        "genreNames": [
            "K-pop"
        ],
        "url": "https://music.apple.com/us/artist/seventeen/999644772",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages211/v4/e7/06/20/e7062051-2a82-4eec-cce8-5f69737a7841/2335faf5-a8e3-40e7-9b90-153f0eb381c1_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "883131348",
        "name": "BTS",
        "genreNames": [
            "K-pop"
        ],
        "url": "https://music.apple.com/us/artist/bts/883131348",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/31/77/b0/3177b0da-f5ec-f46c-473a-bdbf4f2161dc/bc5fdca7-12e1-4ea2-a3e9-f9481d5d25bb_ami-identity-5644914dae79e9896405d9b5b7ac9e89-2023-06-09T04-02-49.194Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "159260351",
        "name": "Taylor Swift",
        "genreNames": [
            "Pop/Country"
        ],
        "url": "https://music.apple.com/us/artist/taylor-swift/159260351",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages112/v4/1d/39/4e/1d394ed5-279c-3186-4b30-e139a3f93adc/8e1d4c20-f1b8-48de-becd-8be82c903060_ami-identity-994af5c375f4c3aa96cd6ced4a700799-2024-03-28T12-56-11.536Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1208839599",
        "name": "NCT DREAM",
        "genreNames": [
            "K-pop"
        ],
        "url": "https://music.apple.com/ba/artist/nct-dream/1208839599",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages112/v4/e6/94/e7/e694e7a2-a904-5ab2-537b-ba0ac5fabad5/23835f47-789c-4b4d-817c-2e0898e6c45b_ami-identity-4aa15572d79c4e920f9ca25e883000b4-2024-03-19T09-16-15.094Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1235849306",
        "name": "NCT 127",
        "genreNames": ["K-Pop"],
        "url": "https://music.apple.com/us/artist/nct-127/1235849306",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/58/93/3c/58933c81-daef-6e6b-7767-ec611c3d69b5/a09d8708-ea43-4899-9ea9-10d70857bd6b_ami-identity-ed1f3087a233ad26eb688255bab23cc0-2023-12-20T01-19-47.475Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "657630070",
        "name": "EXO",
        "genreNames": ["K-Pop"],
        "url": "https://music.apple.com/us/artist/exo/657630070",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/84/c2/3a/84c23ae7-fa57-1567-337c-cd64239c8185/c0dfaa26-cd0d-4798-9580-f62d16b77445_ami-identity-435ed367dda58940fe99cf590d004bc9-2023-07-07T01-55-12.447Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1141774019",
        "name": "BLACKPINK",
        "genreNames": ["K-Pop"],
        "url": "https://music.apple.com/us/artist/blackpink/1141774019",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/91/31/36/91313698-9306-c1a5-5b9c-d429b3d0800d/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1541011620",
        "name": "ENHYPEN",
        "genreNames": ["K-Pop"],
        "url": "https://music.apple.com/us/artist/enhypen/1541011620",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/21/ea/ba/21eaba78-794b-7b5b-a3ba-5fa51a76cc79/f3741af5-7548-46b5-a587-0eeb8fe13e7a_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1526319335",
        "name": "TREASURE",
        "genreNames": ["K-Pop"],
        "url": "https://music.apple.com/us/artist/treasure/1526319335",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/e1/73/03/e173030f-9673-7452-0019-977b13d996cf/9941b323-9a77-4d39-836d-f41201875f64_ami-identity-e48e0fe4d44534c004e07cebe44ad787-2023-07-28T16-49-38.076Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "396754057",
        "name": "One Direction",
        "genreNames": ["Pop"],
        "url": "https://music.apple.com/us/artist/one-direction/396754057",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/9a/c7/0f/9ac70f5b-1234-6b3b-a6eb-68da551e81de/mzl.bpycoruv.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1304823362",
        "name": "Stray Kids",
        "genreNames": ["K-Pop"],
        "url": "https://music.apple.com/us/artist/stray-kids/1304823362",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages211/v4/06/7c/44/067c448e-6903-99c0-1bd5-57c430bd56fe/dc68a9e6-d3b1-48f9-85ed-7283589e5727_ami-identity-ae24761de3608ee52087a99d0cd83d87-2024-04-24T10-59-08.759Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1533299115",
        "name": "Nct",
        "genreNames": ["K-Pop"],
        "url": "https://music.apple.com/us/artist/nct/1533299115",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/3c/13/70/3c137039-7d18-7426-2e9f-520219748927/86c1d7c2-5c3d-4ebc-9f65-11c20022d01c_ami-identity-54fb0df4daf9ed0964189cc037bf8b52-2023-08-25T02-05-28.934Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1540251304",
        "name": "aespa",
        "genreNames": ["K-Pop"],
        "url": "https://music.apple.com/us/artist/aespa/1540251304",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/cc/9b/83/cc9b830e-f046-4331-9749-34cb00e6edb0/7d6c5761-13c4-423c-aac0-2c6fbbc8cd14_ami-identity-a7ba7a1dcf176f3a97d5a241459fcb51-2023-11-09T01-30-17.732Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1037939997",
        "name": "DAY6",
        "genreNames": ["K-Pop", "Rock"],
        "url": "https://music.apple.com/us/artist/day6/1037939997",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages122/v4/a5/c0/39/a5c0394c-d5a0-13e3-4fe0-4b8efe2303c2/4709779f-4e05-40d6-af40-35b3c9b2fef0_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1439301205",
        "name": "ATEEZ",
        "genreNames": ["K-Pop"],
        "url": "https://music.apple.com/us/artist/ateez/1439301205",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features116/v4/06/8f/23/068f23ca-788e-f288-53d0-bdb152139865/mzl.rrgdegkv.jpg/1000x1000bb.jpg"
    },
    {
        "id": "412778295",
        "name": "Ariana Grande",
        "genreNames": ["Pop"],
        "url": "https://music.apple.com/us/artist/ariana-grande/412778295",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages122/v4/08/e5/9e/08e59e63-fd5f-2bd4-62b2-593c0fb3f5c9/92967109-2f7b-43cb-a467-d779246b3302_ami-identity-b7ce039aac2c803c4620ff4ff18920ca-2024-03-06T20-58-51.662Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1454642552",
        "name": "TOMORROW X TOGETHER",
        "genreNames": ["K-Pop"],
        "url": "https://music.apple.com/us/artist/tomorrow-x-together/1454642552",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages122/v4/92/61/b0/9261b04a-2c46-c2c6-16b9-bf79845acc07/20a2ddd9-a874-4e91-8e25-40e32c8b1bd3_ami-identity-0deb1d20fe7062edc8fdaf2072c757a7-2024-03-25T01-40-22.219Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "136975",
        "name": "The Beatles",
        "genreNames": [
            "Rock"
        ],
        "url": "https://music.apple.com/us/artist/the-beatles/136975",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/97/55/a3/9755a36f-77f7-0d03-80ad-ee103316a011/mza_7475051787362065811.png/1000x1000bb.jpg"
    },
    {
        "id": "464296584",
        "name": "Lana Del Rey",
        "genreNames": [
            "Alternative", "Pop"
        ],
        "url": "https://music.apple.com/us/artist/lana-del-rey/464296584",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages112/v4/99/da/66/99da66c2-f540-8406-0646-c95c85d64d04/e3d84d5f-c5b1-4769-8610-c0702bf35962_ami-identity-d8b71c87e7bea71100eb76a0935c9fa0-2022-12-12T15-36-57.679Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1594159996",
        "name": "IVE",
        "genreNames": [
            "K-pop"
        ],
        "url": "https://music.apple.com/us/artist/ive/1594159996",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages211/v4/12/7d/95/127d956d-8ab6-bb32-dd24-7a1324495ab0/845cce83-0453-43eb-8c40-0ee29d7ef585_ami-identity-69240dba41883ac416d4effab06e1b2c-2024-04-27T09-49-54.030Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1203816887",
        "name": "TWICE",
        "genreNames": [
            "K-pop"
        ],
        "url": "https://music.apple.com/us/artist/twice/1203816887",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages122/v4/f5/35/15/f5351523-0435-c809-c601-5f1bc4770dfe/5d648161-ef97-4897-b717-0f9c69963d33_ami-identity-cd0f1d20c8c852a423f4fcf92fac6ef7-2024-02-29T03-20-57.168Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "785052275",
        "name": "Jung Kook",
        "genreNames": [
            "K-pop"
        ],
        "url": "https://music.apple.com/us/artist/jung-kook/785052275",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/4c/a3/8b/4ca38b57-59b2-fbab-217d-62c5d6752c86/74b9ee74-d818-489f-95e6-cbd97f82229c_ami-identity-be4a2146ef9622e76e6ab86b3c394556-2023-11-03T04-02-21.413Z_cropped.png/1000x1000bb.jpg"
    },
    // {
    //     "id": "2715720",
    //     "name": "Kanye West",
    //     "genreNames": [
    //         "Hip-hop", "Rap"
    //     ],
    //     "url": "https://music.apple.com/us/artist/kanye-west/2715720",
    //     "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/6b/ca/47/6bca47fd-8a58-0652-8de8-475394e8159d/pr_source.png/1000x1000bb.jpg"
    // },
    {
        "id": "1635469693",
        "name": "NewJeans",
        "genreNames": [
            "Indie", "Rock"
        ],
        "url": "https://music.apple.com/us/artist/newjeans/1635469693",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/de/e4/a3/dee4a3c5-4b3d-303d-d714-7cb4bbe11abc/125a3ed1-1690-475c-bcf5-7ed318ac579a_ami-identity-10ffb209545338d630cc58ea79bf639b-2023-12-20T06-53-22.980Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "906961899",
        "name": "Red Velvet",
        "genreNames": [
            "K-pop"
        ],
        "url": "https://music.apple.com/us/artist/red-velvet/906961899",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/15/13/d9/1513d993-4e43-9d00-3474-eb2b37bddb58/0b34944d-22c2-4fc8-aa30-f423c39a0241_ami-identity-1666b78174a80837b932d1506f9c6de4-2023-11-09T08-44-35.455Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "958416186",
        "name": "GFRIEND",
        "genreNames": [
            "K-pop"
        ],
        "url": "https://music.apple.com/us/artist/gfriend/958416186",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/10/3d/30/103d3009-9128-b754-bc12-97e405ad17eb/mzl.vtuyubgn.jpg/1000x1000bb.jpg"
    },
    {
        "id": "1449462311",
        "name": "WayV",
        "genreNames": [
            "C-pop", "K-pop"
        ],
        "url": "https://music.apple.com/us/artist/wayv/1449462311",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/8f/ee/86/8fee8652-7bd6-eef1-a3b7-104c6e93810a/775401bd-96e3-4f26-bc54-debf1190c8a1_ami-identity-f656905e35d9f93362288356d4def9f5-2023-10-30T05-42-21.086Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "1614202131",
        "name": "NCT U",
        "genreNames": [
            "K-pop"
        ],
        "url": "https://music.apple.com/us/artist/nct-u/1614202131",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/12/0a/66/120a660b-d48d-0151-9b73-16dfb8755c44/16a12034-3c59-4ade-b44c-afa0f72ad603_ami-identity-487473a6f3611432e5108f77b10a6d1c-2023-08-25T02-00-19.435Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "979458609",
        "name": "Olivia Rodrigo",
        "genreNames": [
            "Pop", "Alternative"
        ],
        "url": "https://music.apple.com/us/artist/olivia-rodrigo/979458609",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/34/dd/36/34dd3678-40c6-9d8b-fa0f-cb6d82d3103b/6c46071d-e4d2-4671-a110-6ad83a63b89b_ami-identity-5b05d351a6e3c7256ef7680c8aef2894-2023-06-30T03-58-10.754Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "479756766",
        "name": "The Weeknd",
        "genreNames": [
            "R&B/Soul"
        ],
        "url": "https://music.apple.com/us/artist/the-weeknd/479756766",
        "editorialNotes": "A subversive vocalist who emerged from the underground to define millennial R&B.",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/2d/85/9c/2d859cc8-7230-8ba7-b741-a7c7c029bed6/8be5558a-5c13-48da-a649-a873856f3fb0_ami-identity-777ad796f94973f081f3e54e4b81fcb1-2023-07-22T13-59-27.161Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "79798",
        "name": "Nina Simone",
        "genreNames": [
            "Jazz"
        ],
        "url": "https://music.apple.com/us/artist/nina-simone/79798",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/71/3d/2d/713d2d6c-7175-1335-1c04-64f4e90a58e8/cdff4cfa-7c37-4de8-a99e-e2ef569f9ee9_ami-identity-15b9e793fd6316eeae2b0f29008d054e-2023-03-22T10-03-41.997Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "21434",
        "name": "Lauryn Hill",
        "genreNames": [
            "R&B/Soul"
        ],
        "url": "https://music.apple.com/us/artist/lauryn-hill/21434",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/17/54/32/1754321f-4d10-0445-8c63-63b9aca63241/mza_5881696461253841876.png/1000x1000bb.jpg"
    },
    {
        "id": "197443",
        "name": "Elvis Presley",
        "genreNames": [
            "Rock"
        ],
        "url": "https://music.apple.com/us/artist/elvis-presley/197443",
        "editorialNotes": "Revisit the King’s unparalleled discography.",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/27/02/36/2702364b-ebda-31ca-e2e2-b60031f6578d/mzl.bblyglnb.png/1000x1000bb.jpg"
    },
    {
        "id": "605800394",
        "name": "SZA",
        "genreNames": [
            "R&B/Soul"
        ],
        "url": "https://music.apple.com/us/artist/sza/605800394",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/78/a9/b3/78a9b3fe-3694-c068-09c5-673fe0e46f38/mza_5912257883088514867.png/1000x1000bb.jpg"
    },
    {
        "id": "966309175",
        "name": "Post Malone",
        "genreNames": [
            "Hip-Hop/Rap"
        ],
        "url": "https://music.apple.com/us/artist/post-malone/966309175",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages126/v4/ea/c1/e8/eac1e88f-a515-f679-c2e3-ae6cae2c4303/3b7fdb91-3d2e-4b53-9b7a-ef1c39f18cea_ami-identity-f6e441ca8bf36b9a475ea669b4b87be7-2023-04-14T22-56-21.301Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "471744",
        "name": "Coldplay",
        "genreNames": [
            "Alternative"
        ],
        "url": "https://music.apple.com/us/artist/coldplay/471744",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages112/v4/d0/3a/2a/d03a2a42-75f3-4fe1-da87-6588c5de12ef/635bd7cc-c6d3-4610-8f38-85b4a1cf3578_ami-identity-627a3a5a7feb2fd65e55602f252c9aa8-2022-11-11T12-07-04.512Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "994656",
        "name": "Led Zeppelin",
        "genreNames": [
            "Rock"
        ],
        "url": "https://music.apple.com/us/artist/led-zeppelin/994656",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/7d/10/7c/7d107c7b-6e9e-d0f3-d3ea-e1a9fe56ac6b/mza_6622134209630040489.png/1000x1000bb.jpg"
    },
    {
        "id": "355779585",
        "name": "Ana Tijoux",
        "genreNames": [
            "Urbano latino"
        ],
        "url": "https://music.apple.com/us/artist/ana-tijoux/355779585",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/c6/c5/92/c6c5925c-252e-7f98-0e65-f45460b059e0/mzl.wvkslazi.png/1000x1000bb.jpg"
    },
    {
        "id": "16401316",
        "name": "Yann Tiersen",
        "genreNames": [
            "Alternative"
        ],
        "url": "https://music.apple.com/us/artist/yann-tiersen/16401316",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/18/63/a1/1863a1bf-747c-8c21-54e2-f51871420d19/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1109477747",
        "name": "Luísa Sonza",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/lu%C3%ADsa-sonza/1109477747",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/0a/0b/16/0a0b16f4-551c-e7a9-6163-41c3bd779a29/d7b79514-cc51-49e3-9f4e-cb19e6d8eabf_file_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "723844870",
        "name": "Marília Mendonça",
        "genreNames": [
            "Sertanejo"
        ],
        "url": "https://music.apple.com/us/artist/mar%C3%ADlia-mendon%C3%A7a/723844870",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/3c/67/c1/3c67c148-b136-fe1a-c700-f4ffb987a24b/c0e3d484-e2c9-4636-9568-de1fc3a0eab5_ami-identity-3d37dc9544b0dc2053f2d58891db2ae8-2023-07-20T20-30-15.427Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "3915743",
        "name": "Freddie Mercury",
        "genreNames": [
            "Rock"
        ],
        "url": "https://music.apple.com/us/artist/freddie-mercury/3915743",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features115/v4/e7/23/e7/e723e743-9e89-c308-e942-cc759e6069e0/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "11862",
        "name": "Johann Sebastian Bach",
        "genreNames": [
            "Classical"
        ],
        "url": "https://music.apple.com/us/artist/johann-sebastian-bach/11862",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/6d/05/46/6d0546f7-8248-d992-471c-3960320173fe/mza_15524077328646909844.png/1000x1000bb.jpg"
    },
    {
        "id": "263190",
        "name": "Franz Liszt",
        "genreNames": [
            "Classical"
        ],
        "url": "https://music.apple.com/us/artist/franz-liszt/263190",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Features126/v4/4a/d2/39/4ad23965-3fc6-f228-a8aa-4eeae36aa82a/mza_6022825041088479623.png/1000x1000bb.jpg"
    },
    {
        "id": "169193461",
        "name": "Fly Project",
        "genreNames": [
            "Dance"
        ],
        "url": "https://music.apple.com/us/artist/fly-project/169193461",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music126/v4/96/8a/cb/968acbf2-a09c-7809-19cb-58ad3cf5cc30/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "1293255720",
        "name": "Bon Entendeur",
        "genreNames": [
            "French Pop"
        ],
        "url": "https://music.apple.com/us/artist/bon-entendeur/1293255720",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/AMCArtistImages116/v4/1a/a4/12/1aa41254-546b-fecb-4cea-c113f15a4c06/0ca0c720-26fe-40c8-a62f-060571316029_ami-identity-b666137c2ef1a8d8041779ae2e443223-2023-10-30T14-20-34.627Z_cropped.png/1000x1000bb.jpg"
    },
    {
        "id": "275310392",
        "name": "Isabelle Pierre",
        "genreNames": [
            "French Pop"
        ],
        "url": "https://music.apple.com/us/artist/isabelle-pierre/275310392",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/f7/7b/9e/f77b9e26-e2bb-d43e-6367-a814e5519dd3/cover.jpg/1000x1000ac.jpg"
    },
    {
        "id": "62820413",
        "name": "Arctic Monkeys",
        "genreNames": [
            "Rock"
        ],
        "url": "https://music.apple.com/us/artist/arctic-monkeys/62820413",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/d9/a6/06/d9a60642-3a9a-c289-326d-9af3928c4784/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "278873078",
        "name": "Bruno Mars",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/bruno-mars/278873078",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music126/v4/6d/ad/28/6dad2828-52c4-01dc-8e33-3ad3c05b73fd/pr_source.png/1000x1000bb.jpg"
    },
    {
        "id": "471260289",
        "name": "Harry Styles",
        "genreNames": [
            "Pop"
        ],
        "url": "https://music.apple.com/us/artist/harry-styles/471260289",
        "artwork": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/00/8f/26/008f26ce-ec78-ad42-9303-260ac3b9701c/pr_source.png/1000x1000bb.jpg"
    },
];

export const SOCIALS = [
    {
      name: "instagram",
      link: "https://www.instagram.com/muzifyhq/",
      icon: <FaInstagram fontSize={'1.5em'} color="#190F96" />
    },
    {
      name: "twitter",
      link: "https://x.com/MuzifyHQ",
      icon: <FaTwitter fontSize={'1.5em'} color="#190F96"/>
    },
    {
      name: "tiktok",
      link: "https://www.tiktok.com/@muzifyhq",
      icon: <FaTiktok fontSize={'1.5em'} color="#190F96"/>
    },
    {
        name: "linkedin",
        link: "https://in.linkedin.com/company/muzifyhq",
        icon: <FaLinkedin fontSize={'1.5em'} color="#190F96"/>
    }
];

export const PAGES = [
    {
        name: "privacypolicy",
        text: "Privacy Policy",
        link: "https://muzify.com/privacy-policy"
    },
    {
        name: "tnc",
        text: "Terms & Conditions",
        link: "https://muzify.com/terms-and-conditions"
    },
    {
        name: "faq",
        text: "FAQs",
        link: "https://teamasset.notion.site/Muzify-Frequently-Asked-Qestions-10c1997bfe678071b55ec93c915351a2?pvs=4"
    },
    {
        name: "partner",
        text: "Partner With Us",
        link: "https://tally.so/r/mKL17D"
    },
    {
        name: "contact",
        text: "Contact Us",
        link: "https://tally.so/r/nWevAN"
    },
];

export const GA_EVENTS = {
    "STICKY_CTA_CLICK" : "TopCTA_MZ",
    "PHONE_CTA_CLICK" : "CTA_MZ",
    "OPEN_QUIZ_CLICK" : "LetsGo_MZ",
    "INSTAGRAM_CLICK": "Insta_Icon_MZ",
    "TWITTER_CLICK": "Twitter_Icon_MZ",
    "LINKEDIN_CLICK": "Linkedin_Icon_MZ",
    "TIKTOK_CLICK": "Tiktok_Icon_MZ",
    "FAQ_CLICK": "FAQs_MZ",
    "PARTNER_WITH_US_CLICK": "PWU_MZ",
    "TERMS_AND_CONDITIONS_CLICK": "TnC_MZ",
    "PRIVACY_POLICY_CLICK": "PP_MZ",
    "MUZIFY_LANDING_PAGE_VIEW": "LPview_MZ",
    "MUZIFY_CONCERTS_CTA_CLICK": "ConcertsCTA_MZ",
    "MUZIFY_ANDROID_WAITLIST_CTA_CLICK": "AndroidWaitlist_MZ",
};

export const CT_EVENTS = {
    "MUZIFY_CONCERTS_CTA_CLICK": "ConcertsCTA_MZ",
}

export const PAST_CONCERTS = [
    {
        artist: "NCT DREAMS",
        city: "TOKYO"
    },
    {
        artist: "SABRINA CARPENTER",
        city: "NYC"
    },
    {
        artist: "IMAGINE DRAGONS",
        city: "MANSFIELD"
    },
    {
        artist: "ADELE",
        city: "MANSFIELD"
    },
]

export type ActiveConcert = {
    artist: string
    image: string
    date: string
    city: string
    artist_id: number
}

export const PAST_WINNERS_VIDEOS = [ fanVideo1, fanVideo2, fanVideo3 ]

export const PAST_WINNERS = [
    {
        video: fanVideo1,
        artist: "Adele",
        dateLoc: "September 24th, Germany"
    },
    {
        video: fanVideo2,
        artist: "Imagine Dragons",
        dateLoc: "August 8th, US"
    },
    {
        video: fanVideo3,
        artist: "Sabrina Carpenter",
        dateLoc: "September 29th, US"
    }
]

export const ANUV_JAIN_CONCERTS: ActiveConcert[] = [
    {
        artist: 'Anuv Jain',
        image: AnuvJain1,
        date: 'Feb 2,',
        city: 'Dubai',
        artist_id: 1388609753
    },
    {
        artist: 'Anuv Jain',
        image: AnuvJain2,
        date: 'Mar 8,',
        city: 'Kolkata',
        artist_id: 1388609753
    },
    {
        artist: 'Anuv Jain',
        image: AnuvJain3,
        date: 'Mar 9,',
        city: 'Guwahati',
        artist_id: 1388609753
    },
]

export const PAPER_KITES: ActiveConcert[] = [
    {
        artist: 'The Paper Kites',
        image: PaperKites1,
        date: 'Mar 20',
        city: 'Bangalore',
        artist_id: 454316567
    },
    {
        artist: 'The Paper Kites',
        image: PaperKites2,
        date: 'Mar 21',
        city: 'Mumbai',
        artist_id: 454316567
    },
]

export const BHARAT_CHAUHAN_TOUR: ActiveConcert[] = [
    {
        artist: 'Bharat Chauhan',
        image: BharatChauhan1, // Replace with the appropriate image variable or URL
        date: 'Feb 9',
        city: 'Bangalore',
        artist_id: 1187920770
    },
    {
        artist: 'Bharat Chauhan',
        image: BharatChauhan2, // Replace with the appropriate image variable or URL
        date: 'Feb 22',
        city: 'Kolkata',
        artist_id: 1187920770
    },
    {
        artist: 'Bharat Chauhan',
        image: BharatChauhan3, // Replace with the appropriate image variable or URL
        date: 'Mar 8',
        city: 'Pune',
        artist_id: 1187920770
    },
    {
        artist: 'Bharat Chauhan',
        image: BharatChauhan4, // Replace with the appropriate image variable or URL
        date: 'Mar 9',
        city: 'Mumbai',
        artist_id: 1187920770
    },
    {
        artist: 'Bharat Chauhan',
        image: BharatChauhan5, // Replace with the appropriate image variable or URL
        date: 'Mar 15',
        city: 'Hyderabad',
        artist_id: 1187920770
    },
    {
        artist: 'Bharat Chauhan',
        image: BharatChauhan6, // Replace with the appropriate image variable or URL
        date: 'Mar 22',
        city: 'Unspecified',
        artist_id: 1187920770
    },
    {
        artist: 'Bharat Chauhan',
        image: BharatChauhan7, // Replace with the appropriate image variable or URL
        date: 'Mar 30',
        city: 'Delhi',
        artist_id: 1187920770
    },
];


export const ADITYA_RIKHARI: ActiveConcert[] = [
    {
        artist: 'Aditya Rikhari',
        image: AdityaRikhari1,
        date: 'Feb 2',
        city: 'Mumbai',
        artist_id: 1508292593
    },
    {
        artist: 'Aditya Rikhari',
        image: AdityaRikhari2,
        date: 'Feb 9',
        city: 'Lucknow',
        artist_id: 1508292593
    },
    {
        artist: 'Aditya Rikhari',
        image: AdityaRikhari3,
        date: 'Feb 15',
        city: 'Indore',
        artist_id: 1508292593
    },
    {
        artist: 'Aditya Rikhari',
        image: AdityaRikhari4,
        date: 'Feb 16',
        city: 'Bangalore',
        artist_id: 1508292593
    },
    {
        artist: 'Aditya Rikhari',
        image: AdityaRikhari5,
        date: 'Feb 21',
        city: 'Hyderabad',
        artist_id: 1508292593
    },
    {
        artist: 'Aditya Rikhari',
        image: AdityaRikhari6,
        date: 'Feb 23',
        city: 'Kolkata',
        artist_id: 1508292593
    },
    {
        artist: 'Aditya Rikhari',
        image: AdityaRikhari7,
        date: 'Mar 1',
        city: 'Delhi',
        artist_id: 1508292593
    },
    {
        artist: 'Aditya Rikhari',
        image: AdityaRikhari8,
        date: 'Mar 2',
        city: 'Dehradun',
        artist_id: 1508292593
    },
]



export const ACTIVE_CONCERTS: ActiveConcert[] = [
    // {
    //     artist: 'Coldplay',
    //     image: concert_coldplay_mum,
    //     date: 'jan 18',
    //     city: 'mumbai',
    //     artist_id: 471744
    // },
    // {
    //     artist: 'Coldplay',
    //     image: concert_coldplay_abu,
    //     date: 'jan 11',
    //     city: 'abu dhabi',
    //     artist_id: 471744
    // },
    // {
    //     artist: 'Coldplay',
    //     image: concert_coldplay_abu_2,
    //     date: 'jan 12',
    //     city: 'abu dhabi',
    //     artist_id: 471744
    // },
    // {
    //     artist: 'Coldplay',
    //     image: concert_coldplay_mum,
    //     date: 'jan 18',
    //     city: 'mumbai',
    //     artist_id: 471744
    // },

    // {
    //     artist: 'Dua Lipa',
    //     image: concert_dualipa_mum,
    //     date: 'nov 30',
    //     city: 'mumbai',
    //     artist_id: 1031397873
    // },
    {
        artist: "Drake",
        image: Drake,
        date: "Feb 2025, $50 Giftcard",
        city: "",
        artist_id: 271256
    },
    // {
    //     artist: "Juice WRLD",
    //     image: JuiceWRLD,
    //     date: "Feb 2025, $50 Giftcard",
    //     city: "",
    //     artist_id: 1368733420
    // },
    {
        artist: "Frank Ocean",
        image: FrankOcean,
        date: "Feb 2025, $50 Giftcard",
        city: "",
        artist_id: 1368733420
    },
    {
        artist: "Kanye West",
        image: Kanye,
        date: "Feb 2025, $50 Giftcard",
        city: "",
        artist_id: 2715720
    },
    {
        artist: "Ken Carson",
        image: KenCarson,
        date: "Feb 2025, $50 Giftcard",
        city: "",
        artist_id: 1434801023
    },
    {
        artist: "Kendrick Lamar",
        image: KendrickLamar,
        date: "Feb 2025, $50 Giftcard",
        city: "",
        artist_id: 368183298
    },
    {
        artist: "Playboi Carti",
        image: PlayboiCarti,
        date: "Feb 2025, $50 Giftcard",
        city: "",
        artist_id: 982372505
    },
    {
        artist: "Taylor Swift",
        image: TaylorSwift,
        date: "Feb 2025, $50 Giftcard",
        city: "",
        artist_id: 159260351
    },
    {
        artist: "Travis Scott",
        image: TravisScott,
        date: "Feb 2025, $50 Giftcard",
        city: "",
        artist_id: 549236696
    },
    {
        artist: "Tyler, The Creator",
        image: TylerTheCreater,
        date: "Feb 2025, $50 Giftcard",
        city: "",
        artist_id: 420368335
    },
    {
        artist: "Yeat",
        image: Yeat,
        date: "Feb 2025, $50 Giftcard",
        city: "",
        artist_id: 1318094493
    }
]

export type PastWinner = {
    artist: string
    image: string
    date: string
    city: string
    link: string
}

export const PAST_CONCERTS_WINNERS: PastWinner[] = [
    {
        artist: 'imagine dragons',
        image: past_concert_3,
        date: 'aug 6',
        city: 'mansfield',
        link: "https://www.instagram.com/reel/C-X0JFryNAH/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
    },
    {
        artist: 'adele',
        image: past_concert_1,
        date: 'aug 31',
        city: 'munich',
        link: "https://www.instagram.com/reel/C_bOSeAO0Co/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
    },
    {
        artist: 'sabrina carpenter',
        image: past_concert_2,
        date: 'sep 29',
        city: 'nyc',
        link: "https://www.instagram.com/reel/DAhyQO0Rozo/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
    },
    {
        artist: 'sabrina carpenter',
        image: past_concert_4,
        date: 'nov 15',
        city: 'los angeles',
        link: "https://www.instagram.com/reel/DCfFQmCqzcI/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
    },
    {
        artist: 'dua lipa',
        image: past_concert_5,
        date: 'nov 30',
        city: 'mumbai',
        link: "https://www.instagram.com/reel/DDCxkgsTU77/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
    },
    {
        artist: 'vampire weekend',
        image: past_concert_6,
        date: 'dec 13',
        city: 'paris',
        link: "https://www.instagram.com/reel/DEGL-IvADkd/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
    },
    {
        artist: 'coldplay',
        image: past_concert_7,
        date: 'jan 11',
        city: 'abu dhabi',
        link: "https://www.instagram.com/reel/DExfEwHIM_I/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
    },
    //   {
    //     artist: 'Coldplay',
    //     image: concert_coldplay_abu_2,
    //     date: 'jan 12',
    //     city: 'abu dhabi',
    //     link: ""
    // },
    // {
    //     artist: 'Coldplay',
    //     image: concert_coldplay_mum,
    //     date: 'jan 18',
    //     city: 'mumbai',
    //     link: ""
    // },
]
